<template>
  <v-container>
    <div v-if="authorizedAccess">
      <router-view></router-view>
    </div>
    <div class="fill-height" v-else>
      <UnauthorizedAccess :user="user" />
    </div>
  </v-container>
</template>

<script>
import jwtdecode from "jwt-decode";
import UnauthorizedAccess from "@/components/mijini/UnauthorizedAccess";
export default {
  components: {
    UnauthorizedAccess,
  },
  data: () => ({
    authorizedAccess: false,
  }),

  created() {
    this.getUser();
  },
  methods: {
    getUser() {
      this.user = jwtdecode(
        this.$store.state.token,
        this.$store.state.accessKey
      );

      if (!this.user.accessLevel.isEmployee) this.authorizedAccess = true;
      else this.authorizedAccess = false;
    },
  },
};
</script>

<style></style>
